<template>
  <div>
    <v-card color="#0F4A6D" class="hidden-md-and-up mb-3" tile elevation="0">
      <v-card-text class="white--text text-uppercase text-center">Pago de la reserva</v-card-text>
    </v-card>
    <v-container>
      <v-card>
        <v-row class="px-3 mb-3">
          <v-col cols="6" class="pa-0">
            <v-card-subtitle class="subtitle-2">{{ people }} Entradas</v-card-subtitle>
          </v-col>
          <v-col cols="6" class="pa-0">
            <v-card-subtitle class="subtitle-2 float-end">Total {{price}} €</v-card-subtitle>
          </v-col>
        </v-row>
      </v-card>
      <div class="mt-3">
        <card-form :amount="price" :url-form="`${$store.state.api_backend}api/${$store.state.reservation.id}/pay`"  btn-confirm="Confirmar reserva"></card-form>
      </div>
<!--      <v-btn block x-large class="orange white&#45;&#45;text"   >-->
<!--        <v-icon small>mdi-star-three-points-outline</v-icon> Confirmar reserva-->
<!--      </v-btn>-->




    </v-container>
  </div>
</template>

<script>
import CardForm from "@/components/CardForm";
export default {
  name: "Payment",
  components: {CardForm},
  mounted() {
    this.$store.commit('setLoading', false)
  },
  computed: {
    price(){
      const reservation = this.$store.state.reservation
      const amphiEvents = this.$store.state.amphiEvents
      const amphiEvent = amphiEvents.find(elem => elem.id === reservation.event_schedule_id.amphi_event_id)
      return  reservation.amphi_table.capacity * amphiEvent.price
    },
    people(){
      return this.$store.state.reservation.amphi_table.capacity
    }
  }
}
</script>

<style scoped>

</style>